// footer-related

.related-footer {
	background-color: #004565;
	padding: 20px 0px;
}

.wrapper.related-wrapper {
	padding: 0px;
	background-color: inherit;
}

.related-header {
	text-align: center;
	color: white;
	font-size: 2em;
	padding: 7px;
	font-family: Roboto,Open Sans,sans-serif;
	font-weight: 500;
	letter-spacing: .2px;
	text-transform: uppercase;
	font-style: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

.related-col {
	float: left;
	width: 33%;
	padding: 0px 8px;
}

.related-col-content {
	max-width: 300px;
	margin: auto;
}

a.related-link {
	color: black;
}

.related-caption {
	font-size: 0.85em;
	padding: 5px 10px;
	background: #243842;
	color: white;
	display: inline-block;
	width: 100%;
}

.related-name {
	background: white;
	width: 100%;
	display: inline-block;
	padding: 15px;
}

@media screen and (max-width: 700px) {
  .related-col {
	width: 100%;
	padding: 0px 0px;
	margin: 20px 0px;
  }
}

// footer

.site-footer {
	padding: 30px 0;
	background: #262626;
}

div.footer-content {
	background-color: inherit;
}

.footer-content a {
	color: white;
}

.footer-content a:visited {
	color: white;
}

// footer-col

.footer-col-wrapper {
	font-size: 15px;
	color: #828282;
}

.footer-col {
	float: left;
	margin-bottom: 15px;
}

.footer-col-1 {
	width: 33%;
}

.footer-col-2 {
	width: 25%;
}

.footer-col-3 {
	width: 42%;
}

@media screen and (max-width: 600px) {
  .footer-col-1,
  .footer-col-2,
  .footer-col-3 {
	width: 100%;
  }
}

.footer-col > strong {
	color: white;
}

// footer-logo

.logo-footer {
	width: 180px;
	padding-bottom: 10px;
}

.footer-grey-link, .footer-grey-link:visited {
	color: #828282 !important;
}


// footer-list

.footer-list {
	list-style-type: none;
	margin-left: 0px;
	margin-top: 10px;
	padding-left: 10px;
	border-left-style: solid;
	border-color: #5a5353;
	border-width: 2px;
	font-family: Roboto,Open Sans,sans-serif;
	font-weight: 500;
	letter-spacing: .2px;
	text-transform: uppercase;
	font-style: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}

.footer-list li {
	padding: 5px 0px;
}


// footer-callout

.footer-callout {
	font-size: 15px;
	margin-bottom: 5px;
}

.footer-callout-button {
	display: inline-block;
	color: #fff !important;
	background: #355c4b;
	font-size: 1.1em;
	width: 100%;
	text-align: center;
	padding: 10px 0px;
	margin-top: 5px;
	font-family: Roboto,Open Sans,sans-serif;
	font-weight: 500;
	letter-spacing: .2px;
	text-transform: uppercase;
	font-style: normal;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
}


// OLD

.footer-heading {
	font-size: 18px;
	margin-bottom: 15px;
	color: #ffffff;
}
