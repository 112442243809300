.paint {
    background-color: #009688;
    position: relative;
    color: white;
    margin: 30px -25px;
    padding: 15px 25px;
    z-index: 1;
}

.paint:before {
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 0;
    width: 300%;
    left: -100%;
    height: 100%;
    z-index: -1;
    background-color: inherit;
}

/* Google Maps */
iframe.gmaps {
    width: 100%;
    padding-top: 0px;
}

/* YH-table */

.yh-table-wrapper {
    border-color: #184240;
    border-width: 5px;
    border-style: solid;
}

h3.yh-table-heading {
    margin-left: 15px;
    margin-top: 10px;
}

table.yh-table {
    margin-bottom: 0px;
    border-top-style: none;
}

table.yh-table th {
    background-color: white;
    border-style: none;
}

table.yh-table td, th {
    padding: 0px 12px;
}

/* YH FAQ */

.faq-wrapper.yh-faq {
    float: none;
    width: 100%;
    clear: none;
}

.links-wrapper.yh-links {
    float: none;
    width: 100%;
    clear: none;
}

/* YH Partners */

.partners-wrapper {
    padding-bottom: 20px;
    display: inline-block;
}

.partners {
    margin-left: 0px;
    margin-bottom: 0px;
}

.partners li {
    display: inline-block;
    padding: 10px 25px;
}

.partners li:before {
    content: "\2022";
    color: #004565;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

/* Om oss */
.employee-wrapper {
    padding: 20px;
    box-sizing: border-box;
    font-size: 14px;
}

.employee-img {
    border-radius: 50%;
    padding-top: 30px;
}

.employee-name {
    text-align: center;
    display: block;
    font-weight: 600;
    padding-top: 5px;
}

.employee-role {
    text-align: center;
    display: block;
    padding-bottom: 8px;
}
