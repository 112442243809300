// Unknown

a.contact-cta {
    background-color: #009855;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff !important;
    font-size: 15px;
    font-weight: bold;
    padding: 12px 43px;
    text-decoration: none;
}

a.contact-cta:hover {
    background-color: #004c4f;
}

// FAQ
.collapse {
  display: block;
  max-height: 0px;
  overflow: hidden;
  transition: max-height .5s cubic-bezier(0, 1, 0, 1);; 

  &.show {
    max-height: 99em;
    transition: max-height .5s ease-in-out;
  }
}

.block {
  margin-top: 10px;
  // height: 250px;
  
  &__content {
    padding-bottom: 10px;
    height: 100%;
  }
}

button.btn.expand {
    border: none;
    background-color: #4caf5000;
    padding: 5px 12px;
    font-size: 15px;
    cursor: pointer;
    text-align: left;
    width: 100%;
}

p.question-border {
    border: 1px solid #e5e5e5;
    padding-bottom: 3px;
    padding-top: 3px;
}

// Links
.links-wrapper {
    margin-bottom: 15px;
}

.external-link {
    display: block;
}
